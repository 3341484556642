@font-face {
    font-family: 'BEBAS';
    src: url('../../webfonts/BebasNeue-Regular.eot');
    src: url('../../webfonts/BebasNeue-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../webfonts/BebasNeue-Regular.woff2') format('woff2'),
        url('../../webfonts/BebasNeue-Regular.woff') format('woff'),
        url('../../webfonts/BebasNeue-Regular.ttf') format('truetype'),
        url('../../webfonts/BebasNeue-Regular.svg#BebasNeue-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "HYShangWeiShouShuW";
    src: url("../../webfonts/HYShangWeiShouShuW.eot");
    src: url("../../webfonts/HYShangWeiShouShuW.eot?#iefix") format("embedded-opentype"),
        url("../../webfonts/HYShangWeiShouShuW.woff") format("woff"),
        url("../../webfonts/HYShangWeiShouShuW.ttf") format("truetype")
        url("../../webfonts/HYShangWeiShouShuW.svg#HYShangWeiShouShuW") format("svg");
    font-style: normal;
    font-weight: normal;
}